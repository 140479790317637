// external imports
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

// helpers
import moment from 'moment'
import { up, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData } from '../lib/helpers'
import injectIntl from '../lib/intl/injectIntl'
import withExtraction from '../lib/withExtraction'
import { categoriesKeysObject } from '../courses'

// components
import SEO from '../components/SEO'
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Button,
  HeaderBar,
  Card,
  SubHeaderBar,
} from '../components'
import { Page, PageContent } from '../components/Page'
import ClosestCoursesBox from '../components/ClosestCoursesBox'
import headerBarImageSrc from '../data/images/journey-top.png'

class Journey extends React.Component {
  componentDidMount() {}

  render() {
    const {
      location: { pathname },
      intl: { language },
    } = this.props

    const { allCourseTerms } = this.props.data

    const itemsToList = allCourseTerms
      .filter(term => term.isPublished)
      .filter(term => moment(term.from, 'YYYY-MM-DD').isAfter(moment()))
      .filter(
        term =>
          term.courseType.category === categoriesKeysObject.FIE_STANDARD &&
          moment(term.from, 'YYYY-MM-DD').isAfter(moment()) &&
          !term.courseType.isFIE1
      )
      .slice(0, 10)

    return (
      <>
        <SEO title="Cesta lektora" pathname={pathname} />
        <HeaderBar>
          <HeaderBar.Content>
            <HeaderBar.Title>Cesta lektora</HeaderBar.Title>
            <HeaderBar.SubTitle>
              staráme se o lektora po celou dobu jeho cesty
            </HeaderBar.SubTitle>
          </HeaderBar.Content>
          <HeaderBar.ImageWrapper mobileMaxWidth="400px">
            <img src={headerBarImageSrc} alt="" />
          </HeaderBar.ImageWrapper>
        </HeaderBar>

        <SubHeaderBar contentMaxWidth="1220px">
          <SubHeaderBar.Text>
            Jedním z hlavních cílů Akreditovaného tréninkového centra metod pro
            osobní rozvoj je{' '}
            <strong>šířit Metodu instrumentálního uvědomování</strong> nejen v
            českém a slovenském prostředí. Rádi poradíme a pomůžeme všem, kteří
            se o metodu zajímají a sami by se chtěli
            <strong>stát lektory.</strong>
          </SubHeaderBar.Text>
          <Gap gap="140px" mobileGap="48px" />
          <SubHeaderBar.Text>
            O naše lektory se staráme po{' '}
            <strong>celou dobu jejich cesty,</strong> od úvodního poskytnutí
            informací a zaškolení po další vzdělávání v{' '}
            <strong>navazujících kurzech</strong>
            nebo seminářích. Spolupracujeme rovněž s mezinárodními institucemi.
          </SubHeaderBar.Text>
        </SubHeaderBar>

        <Page>
          <Gap gap="90px" mobileGap="60px" />
          <PageContent>
            <Card.Grid>
              <Card>
                <Card.Header>
                  POSKYTNUTÍ
                  <br />
                  INFORMACÍ
                </Card.Header>
                <Card.Content>
                  <Card.PencilIcon />
                  <Card.Text>
                    Nabízíme ukázkové lekce, akreditované{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.TASTING }}
                    >
                      „ochutnávky“
                    </Link.TextLink>{' '}
                    i soukromé konzultace.
                  </Card.Text>
                  <Card.Button
                    link={{
                      asA: true,
                      href: 'mailto:info@a-t-c.cz',
                    }}
                  >
                    napište nám
                  </Card.Button>
                </Card.Content>
              </Card>
              <Card>
                <Card.Header>zaškolení na úvodním kurzu</Card.Header>
                <Card.Content>
                  <Card.HatIcon />
                  <Card.Text>
                    Pokud vás metody zaujaly natolik, že byste je sami chtěli
                    školit, přihlašte se na kurz{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.FIE_STANDARD }}
                    >
                      MIU Standard I
                    </Link.TextLink>
                  </Card.Text>
                  <Card.Button
                    link={{
                      pageKey: Link.COURSES,
                      anchor: Link.anchors.COURSES_BOX,
                      params: { active: categoriesKeysObject.FIE_STANDARD },
                    }}
                  >
                    detail kurzu
                  </Card.Button>
                </Card.Content>
              </Card>
              <Card>
                <Card.Header>podpora v průběhu praxe</Card.Header>
                <Card.Content>
                  <Card.ConnectedPersonsIcon />
                  <Card.Text>
                    Nabízíme supervize, pořádáme{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.CONFERENCE }}
                    >
                      konference
                    </Link.TextLink>{' '}
                    i{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.TASTING }}
                    >
                      inspirační semináře
                    </Link.TextLink>{' '}
                    nebo umožňujeme{' '}
                    <Link.TextLink
                      asA
                      target="_blank"
                      href="https://cs.bbmediator.com/output-1-become-a-better-mediator"
                    >
                      sdílení zkušeností lektorů
                    </Link.TextLink>
                  </Card.Text>
                  <Card.Button
                    link={{
                      pageKey: Link.COURSES,
                      anchor: Link.anchors.COURSES_BOX,
                      params: { active: categoriesKeysObject.TASTING },
                    }}
                  >
                    více inspirace
                  </Card.Button>
                </Card.Content>
              </Card>
              <Card>
                <Card.Header>návazné vzdělávání</Card.Header>
                <Card.Content>
                  <Card.BookIcon />
                  <Card.Text>
                    Přihlašte se na navazující kurzy z řady{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.FIE_STANDARD }}
                    >
                      Standard
                    </Link.TextLink>{' '}
                    (MIU II a MIU III) či{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.FIE_BASIC }}
                    >
                      Basic
                    </Link.TextLink>
                    .
                  </Card.Text>
                  <Card.Button
                    link={{
                      pageKey: Link.COURSES,
                    }}
                  >
                    naše kurzy
                  </Card.Button>
                </Card.Content>
              </Card>
            </Card.Grid>
          </PageContent>
        </Page>
        <Gap gap="128px" mobileGap="60px" />

        <Page bg="bgGrey">
          <PageContent>
            <Gap gap="60px" mobileGap="60px" />
            <Col width="100%" alignItems="stretch">
              <ClosestCoursesBox
                items={itemsToList}
                title="Nejbližší nabízené návazné kurzy"
                link={{ pageKey: Link.COURSES }}
              />
            </Col>
            <Gap gap="120px" mobileGap="70px" />
          </PageContent>
        </Page>
      </>
    )
  }
}

export default injectIntl(withExtraction(Journey))

export const pageQuery = graphql`
  query {
    ks {
      allCollaborators(where: { isPublished: true }) {
        ...CollaboratorFragment
      }
      allCourseTerms(sortBy: from_ASC) {
        ...CourseTermFragment
        ...CourseTermNestedFragment
      }
    }
  }
`
